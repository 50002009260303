<template>
  <div class="family-portal-members" v-if="hasPerm('portal.view_account')">
    <page-header title="Comptes du Portail famille" icon="fas fa-users"></page-header>
    <div v-show="entities.length === 0 && !isLoading(loadingName)" class="empty-text">Aucun compte</div>
    <div class="sub-header" v-show="entities.length">
      <b-row>
        <b-col cols="3">
          <div class="small-text">
            <b>
              <counter-label :counter="entities.length" label="famille"></counter-label>
              -
              <counter-label
                :counter="accounts.length" label="compte"
              ></counter-label>
              -
              <counter-label
                :counter="activeAccounts.length" label="compte actif" label-n="comptes actifs"
              ></counter-label>
            </b>
          </div>
        </b-col>
        <b-col cols="3">
          <div>
            <b-select id="only" v-model="only">
              <b-select-option :value="0">Inscrits aux accueils de loisirs</b-select-option>
              <b-select-option :value="1">Inscrits aux activités</b-select-option>
              <b-select-option :value="2">Inscrits aux accueils et aux activités</b-select-option>
              <b-select-option :value="99">Tous</b-select-option>
            </b-select>
          </div>
        </b-col>
        <b-col cols="3">
          <b-form-group id="entity-filter-group">
            <b-input type="text" v-model="entityFilter" placeholder="Filtrer par nom de famille"></b-input>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="text-right">
          <b-form-group id="filter-select-group">
            <b-select
                id="selectFilter"
                v-model="selectedFilter"
              >
              <b-select-option v-for="elt of filterChoices" :key="elt.id" :value="elt">
                {{ elt.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="!isLoading(loadingName)">
      <div v-for="entity of filteredEntities" :key="entity.id" class="field-line">
        <b-row>
          <b-col cols="3">
            <a href @click.prevent="showEntitySidebar(entity)">{{ entity.name }}</a>
          </b-col>
          <b-col cols="9">
            <div v-if="getIndividuals(entity).length === 0">
              <b-row>
                <b-col cols="4">
                  <div v-for="individual of getNoEmailIndividuals(entity)" :key="individual.id">
                    <a href @click.prevent="showIndividualSidebar(individual)">{{ individual.firstAndLastName() }}</a>
                  </div>
                </b-col>
                <b-col cols="4">Aucun adulte ne dispose d'un email</b-col>
                <b-col cols="4" class="text-right">
                  <router-link :to="getEntityLink(entity)">Saisir un email</router-link>
                </b-col>
              </b-row>
            </div>
            <div v-for="individual of getIndividuals(entity)" :key="individual.id">
              <b-row>
                <b-col cols="4">
                  <a href @click.prevent="showIndividualSidebar(individual)">{{ individual.firstAndLastName() }}</a>
                </b-col>
                <b-col cols="4"><email-link :email="individual.email"></email-link></b-col>
                <b-col cols="4" class="text-right">
                  <span v-if="getAccount(individual) === null">
                    <a
                      class="btn btn-primary btn-xs"
                      @click.prevent="createAccount(entity, individual)"
                    >
                      <i class="fa fa-plus"></i> Créer le compte
                    </a>
                  </span>
                  <span v-else-if="getAccount(individual).user.isActive">
                    <span class="badge badge-success"><i class="fa fa-check"></i> Compte actif</span>
                  </span>
                  <span v-else>
                    <a
                      v-if="!notifiedRecently(getAccount(individual))"
                      class="btn btn-secondary btn-xs"
                      @click.prevent="sendActivationEmail(getAccount(individual))"
                    >
                      <i class="fa fa-send"></i> Envoyer l'email d'activation
                    </a>
                    <div class="small2">
                      <span v-if="!getAccount(individual).notifiedOn">Pas encore notifié</span>
                      <span v-else>
                        <span class="badge" :class="{ 'badge-warning': notifiedRecently(getAccount(individual)) }">
                          Notifié le {{ getAccount(individual).notifiedOn | dateToString('lll') }}</span>
                        </span>
                    </div>
                  </span>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import { dateToString } from '@/filters/texts'
import { makeEntity } from '@/types/people'
import { makeAccount } from '@/types/portal'
import { makeChoice } from '@/types/base'
import { daysAgo } from '@/utils/dates'
import EmailLink from '@/components/Controls/Links/EmailLink.vue'

export default {
  name: 'family-portal-entities',
  props: {
  },
  mixins: [BackendMixin],
  components: {
    EmailLink,
    LoadingGif,
    PageHeader,
    CounterLabel,
  },
  data() {
    return {
      loadingName: 'family-portal-members',
      entities: [],
      accountsMap: new Map(),
      accounts: [],
      selectedFilter: null,
      filterChoices: [],
      entityFilter: '',
      only: 0,
    }
  },
  async created() {
    this.filterChoices = [
      makeChoice({ id: 1, name: 'Toutes les familles', }),
      makeChoice({ id: 2, name: 'Familles avec un compte à créer', }),
      makeChoice({ id: 3, name: 'Familles avec un compte inactif', }),
      makeChoice({ id: 4, name: 'Familles avec un compte à relancer', })
    ]
    this.selectedFilter = this.filterChoices[0]
    await this.loadData()
  },
  watch: {
    only: function() {
      this.loadData()
    },
  },
  computed: {
    activeAccounts() {
      return this.accounts.filter(elt => elt.user.isActive)
    },
    filteredEntities() {
      let entities = this.entities
      if (this.selectedFilter && this.selectedFilter.id > 1) {
        entities = []
        for (const entity of this.entities) {
          let ignore = true
          for (const individual of this.getIndividuals(entity)) {
            const account = this.getAccount(individual)
            if (this.selectedFilter.id === 2 && !account) {
              ignore = false // pas de compte
            }
            if (this.selectedFilter.id === 3 && account && !account.user.isActive) {
              ignore = false // compte inactif
            }
            if (this.selectedFilter.id === 4 && account && !account.user.isActive) {
              if (!this.notifiedRecently(account)) {
                ignore = false // compte à relancer
              }
            }
          }
          if (!ignore) {
            entities.push(entity)
          }
        }
      }
      if (this.entityFilter) {
        const entityFilter = this.entityFilter.toLowerCase()
        entities = entities.filter(
          elt => elt.name.toLowerCase().search(entityFilter) >= 0
        )
      }
      return entities
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      this.startLoading(this.loadingName)
      try {
        const url = '/api/portal-entities-list/?only=' + this.only
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.entities = resp.data.entities.map(elt => makeEntity(elt))
        this.accounts = []
        this.accountsMap = new Map()
        for (const accountData of resp.data.accounts) {
          const account = makeAccount(accountData)
          this.accounts.push(account)
          this.accountsMap.set(account.individual.id, account)
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    getEntityLink(entity) {
      return { name: 'families-detail', params: { entityId: '' + entity.id, }, }
    },
    getIndividuals(entity) {
      return entity.memberships.filter(
        membership => ((membership.role.isMain) && !(membership.role.isChild) && (membership.individual.email))
      ).map(membership => membership.individual)
    },
    getNoEmailIndividuals(entity) {
      return entity.memberships.filter(
        membership => ((membership.role.isMain) && !(membership.role.isChild) && (!membership.individual.email))
      ).map(membership => membership.individual)
    },
    getAccount(individual) {
      return this.accountsMap.get(individual.id) || null
    },
    async createAccount(entity, individual) {
      const data = {
        individuals: [individual.id],
        keep: 1,
      }
      let backendApi = new BackendApi('post', '/api/create-entity-accounts/' + entity.id + '/')
      const loadingName = 'create-account-' + individual.id
      this.startLoading(loadingName)
      try {
        let resp = await backendApi.callApi(data)
        if (resp.data.messages.length) {
          await this.addSuccess(resp.data.messages.join('. '))
        }
        const accounts = resp.data.accounts.map(elt => makeAccount(elt))
        if (accounts.length) {
          this.accountsMap.set(individual.id, accounts[0])
          this.accountsMap = new Map(this.accountsMap) // copy for refresh
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(loadingName)
      // await this.loadEntityAccounts()
    },
    async sendActivationEmail(account) {
      try {
        const backendApi = new BackendApi('post', '/api/send-account-activation/')
        const resp = await backendApi.callApi({ account: account.id, })
        account.notifiedOn = new Date()
        await this.addSuccess(resp.data.message)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    notifiedRecently(account) {
      return daysAgo(account.notifiedOn) < 3
    },
  },
}
</script>

<style lang="less">
.family-portal-members > .field-line {
  background: #eee;
}
.family-portal-members > .field-line:nth-of-type(odd) {
  background: #fff !important;
}
.field-line.bold {
  font-weight: bold;
}
</style>
